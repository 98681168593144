@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Montserrat:ital@0;1&family=Open+Sans:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "Fontspring-DEMO-proximanovacond-regular";
  src: url("./Fonts/Fontspring-DEMO-proximanovacond-regular.otf") format("opentype");
}

@font-face {
  font-family: "more-sugar.regular";
  src: url("./Fonts/more-sugar.regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bimbo-Regular-trial";
  src: url("./Fonts/Bimbo-Regular-trial.ttf") format("truetype");
}

@font-face {
  font-family: "ChildrenSans";
  src: url("./Fonts/ChildrenSans.ttf") format("truetype");
}

@font-face {
  font-family: "Blueberry";
  src: url("./Fonts/Blueberry\ .ttf") format("truetype");
}

@font-face {
  font-family: "Agrandir-TextBold";
  src: url("./Fonts/Agrandir-TextBold.otf") format("truetype");
}




:root {

  --color-gold: #991B1F;
  --color-grey: #154D9B;
  --color-green: #81B340;
  --color-light-grey: #CBC6C6;
  --color-gold-orange: #F1B721;

  /* font for all titles */
  --font-logo: "Roboto";
  /* font for green text */
  --font-green: "more-sugar.regular";
  /* font signature */
  --font-signature: "Bimbo-Regular-trial";
  /* button font */
  --font-btn: "ChildrenSans";
  /* font for all body text */
  --font-proxima: "Fontspring-DEMO-proximanovacond-regular";
  /* testimony signature */
  --font-testimony-signature: "Blueberry";
  /* font Contact */
  --font-contact: "Agrandir-TextBold";
  /* font for btn text */
  --font-anton: "Anton";


}