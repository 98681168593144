.img {
    /* width: 90%; */
    margin: 0 auto;
    background-color: rgb(240 240 238 / 79%);
    border-radius: .5rem;
}


.testimonialDiv h3,
.testClass,
.testClass2 {
    font-size: 4.8vw;
    line-height: 6vw;
    color: var(--color-grey);
    font-family: var(--font-logo);
    font-weight: bold;
    text-align: center;
}

.testClass,
.testClass2 {
    margin-top: 3vw;
    font-size: 3.9vw;
    line-height: 4vw;
    font-style: italic;
    /* background-color: rgba(240, 255, 255, 0.811); */
    border-radius: .3rem;
    padding: 5vw 7.2vw;
}

.testClass2 {
    margin-top: -2vw;
}

.testClass>p,
.testClass2>p {
    text-align: left;
    font-size: 5.5svw;
    margin: 5vw 0 0 0;
    font-family: var(--font-testimony-signature);
    font-style: normal;
}

.readMoreButton {
    font-family: var(--font-btn);
    font-size: 5vw;
    font-weight: bold;
    border: 2px solid white;
    width: 38vw;
    align-content: end;
    line-height: 6vw;
    background-color: #c3c8c8;
    padding: 1.5vw 1.5vw 1vw 1.5vw;
    color: #154d9b;
}

.readMoreButton:hover {
    background-color: #898f8f;
    border: 2px solid white;
    color: white;
    font-weight: normal;
    transition: 0.2s;
}

.portTwo {
    width: 110% !important
}

.porthree {
    width: 70% !important;
}

.introContainer {
    width: 95%;
    margin: 7px 0 25px 0;
    padding: 0 .5rem 38px .5rem;
    display: flex;
    flex-direction: column;
    align-items: right;
    position: relative;
    z-index: 1;
}

.introContainer>p {
    font-size: 6vw;
    color: white;
    font-family: var(--font-btn);
    text-align: right !important;
    margin: 6vw 0 0 0;
}

.contactBtn {
    display: flex;
    justify-content: end;
    font-family: var(--font-btn);
    display: flex;
}

.btnClass {
    font-size: 6vw;
    background-color: #125830;
    border: 2px solid white;
    padding-top: 9px;


}

.btnClass:hover {
    border: 2px solid #0f3c22;
    background-color: #125830;

}

.titleContainer {
    margin: 1rem 0 0 0;
    width: 100%;
    text-align: center;
}

.contentContainer {
    width: 100%;
    background-image: url("../../assets/images/mobile-red-green-bottom.png");
    background-size: 100% 125%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -50px;
    z-index: 1;
}

.titleContainer>h3 {
    text-align: center;
    font-size: 6vw;
    font-weight: 800;
    margin-top: 11vw;
    font-family: var(--font-logo);
    color: var(--color-grey);
}

.mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* @media screen and (min-width:600px) {

    .introContainer p {
        margin: 2vw 7.5vw;
        font-size: 4.4vw;
    }
} */

@media screen and (min-width:728px) {

    .contentContainer {
        background-image: url("../../assets/images/desktop-grey-abstract.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100% 80%;

    }

    .titleContainer>h2,
    .titleContainer>h3 {
        text-align: center;
        font-size: 3.5vw;
        font-weight: 800;
        margin-top: 0;
        font-family: var(--font-logo);
        color: var(--color-grey);
    }

    .testimonialDiv h3 {
        font-size: 1.99vw;
        line-height: 2.8vw;
        padding: 2vw;
        text-align: left;
    }

    .titleContainer>h2 {
        margin-top: 4vw;
    }

    .readMoreButton {
        font-size: 3vw;
        line-height: 2vw;
        width: fit-content;
        padding: 2vw 2vw 1.5vw 2vw;

    }

    .img {
        background-color: transparent;
        /* display: flex; */
        /* height: 267px;
        width: 100%; */
        width: 52%;
    }

    .portOne {
        width: 51% !important;
        height: 35vw !important;
        margin-top: 1vw;
    }

    .testClass,
    .testClass2 {
        font-size: 2.1vw;
        line-height: 2.4vw;
        width: 74vw;
        margin: 1vw 0 0 -105vw;
        padding: 1vw 2.2vw;
    }

    .testClass>p,
    .testClass2>p {
        font-size: 3.5vw;
        /* margin: 2vw 0 0 5vw; */
    }

    .testClass2>p {
        margin: 2vw 0 0 3vw;
    }

    .introContainer {
        margin: -18px 0 25px 0;

    }

    .introContainer>section {
        background-image: url("../../assets/images/desktop-red-green-bottom.png");
        background-size: 111% 100%;
        background-repeat: repeat;
        height: 70vw;
        width: 100vw;
        margin: -262px 0 0 -38px;
        position: relative;
        z-index: -1;
    }

    .introContainer>p {
        margin: -32vw 0 0 0;
        font-size: 3vw;
    }

    .btnClass {
        font-size: 2.5vw;
        background-color: #cdda48;
        color: #11572f;
        font-weight: bold;

    }

}

@media screen and (min-width: 800px) {
    .introContainer>section {
        margin: -324px 0 0 -38px !important;
    }

    .testClass,
    .testClass2 {
        margin-top: 1vw;
    }

    .portTwo {
        margin-top: -2.5vw;
    }

    .titleContainer>h3 {
        margin-top: 3vw;
    }

    .contentContainer {
        position: relative;
        top: -10px;
    }

}

@media screen and (min-width: 900px) {
    .introContainer>section {
        margin: -384px 0 0 -38px !important;
    }

    /* .img {
        max-width: 672px;
    } */
}

@media screen and (min-width: 1000px) {
    .introContainer>section {
        /* margin: -424px 0 0 -38px !important; */
        margin: -455px 0 0 -38px !important;
    }
}

@media screen and (min-width: 1500px) {
    .titleContainer>h3 {
        margin-top: 33px;
        font-size: 58px;
        line-height: 60px;
    }

    .testimonialDiv h3 {
        font-size: 28.99px;
        line-height: 32.99px;
        padding: 21px;
    }

    .img {
        /* height: 630px; */
    }

    .testClass,
    .testClass2 {

        font-size: 29px;
        line-height: 33px;
        width: 775px;
        padding: 55px 11.2px;
        margin-top: -15px;
        width: 1265px;
        margin-left: -249px;
    }

    .testClass>p,
    .testClass2>p {
        font-size: 43.5px;
    }

    .testClass2>p {
        margin: 38px 0 0 29px;
    }

    .introContainer>section {
        background-size: 100% 100%;
        margin: -644px 0 0 -58px !important;
        height: 1060px;
        width: 1515px;
    }

    .introContainer>p {
        margin: -442px 0 0 0;
        font-size: 46px;
    }

    .btnClass {
        font-size: 42.5px;
    }
}

@media screen and (min-width: 1550px) {
    .introContainer>section {
        margin: -644px 0 0 -47px !important;
        height: 1060px;
        width: 1502px;
    }

}