.name,
.phone,
.email,
.message,
.firstName,
.lastName,
.nameContact,
.phoneContact,
.emailContact,
.messageContact {
    background-color: white;
    margin-top: 2vw;
    padding: 2.5vw 0 2.5vw 2vw;
    font-size: 4vw;
    color: black !important;
    border-radius: .2rem;
}


.email::placeholder,
.name::placeholder,
.message::placeholder,
.phone::placeholder,
.name::placeholder,
.lastName::placeholder,
.firstName::placeholder .emailContact::placeholder,
.nameContact::placeholder,
.phoneContact::placeholder,
.messageContact::placeholder {
    color: black !important;
}

.nameDiv {
    /* display: flex; */
    gap: .2rem
}


.formStyle,
.desktopFormStyle {
    padding: 0 6vw 8vw 6vw;
    background-image: url("../../assets/images/mobile-green-contact-rectangle.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    border-radius: .5rem;
    border: none;
    width: 90%;
    margin: 45vw auto 0 auto;
}


.linkStyle,
.linkStyleFooter {
    color: white;
    text-decoration: none;
    font-size: 3.5vw;
}

.linkStyleFooter {
    color: white;
    font-weight: bold;
}

.linkStyle:hover {
    color: var(--color-grey);
}

.linkStyleFooter:hover {
    color: white;
}

.icon,
.iconFooter {
    width: 7vw
}

.icon:hover {
    filter: invert(.55);
    cursor: pointer;
}

.iconFooter {
    padding-top: .2rem;
    width: 10vw;
    /* filter: invert(1); */
    cursor: pointer;
}

.iconFooter:hover {
    filter: invert(0);
}

.contactWrapper,
.contactWrapperBottom,
.contactWrapperBottomFooter {
    display: flex;
    text-align: left;
    font-size: 2.99vw;
    color: white;
    letter-spacing: -.08vw;
}

.contactWrapperBottom,
.contactWrapperBottomFooter {
    display: block;
}

.addressText,
.socialSectionFooter,
.addressTextFooter,
.hoursTextFooter {
    width: 28vw;
    border-right: solid 2px var(--color-gold-orange);
    margin: 0 1.5vw 0 0;
}

.addressTextFooter,
.hoursTextFooter {
    width: 100%;
    border-right: solid 2px black
}

.spanText {
    color: var(--color-gold)
}

.contact {
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/mobile-contact-houses.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* background-color: #1d1d20; */
    padding: 5vw 0 5vw 0;
}

.contactTitle {
    text-align: center;
    font-size: 11.1vw;
    line-height: 17vw;
    font-family: var(--font-logo);
    font-weight: bold;
    color: white;
    margin: 6vw 0 14px 0;
}

.checkForm {
    text-align: center;
    font-size: 3vw;
    color: white;
    text-shadow: 0px 0px 7px #02020254;
}

.btn,
.btnContact,
.btnContactDisabled {
    background-color: white !important;
    color: black;
    width: 100%;
    padding: 2vw 0 2vw 0;
    font-size: 4vw;
    border: none;
}

.btnContactDisabled {
    background-color: #cbcbcb !important;
}

.btn:hover,
.btnContact:hover {
    background-color: var(--color-gold-orange) !important;
    transition: 0.2s !important;
    color: black;
}

.btnContainer {
    text-align: center;
}


@media screen and (min-width:728px) {
    .contact {
        background-image: url("../../assets/images/desktop-contact-houses.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .contactTitle {
        font-size: 7.1vw;
        line-height: 2vw;
        margin: 4.5vw 0 38px 0;
    }

    .contactWrapperBottom, :link {
        font-size: 2vw;
    }

    .contactWrapperBottom>br {
        display: none;
    }

    .contactWrapperBottom {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 3vw;
    }

    .desktopFormStyle,
    .formStyle {
        background-image: url("../../assets/images/-desktop-green-contact-rectangle.png");
        /* background-size: cover; */
    }

    .formStyle {
        margin: 80px 0 0 0;
        padding: 0 6vw 2vw 6vw;
    }

    .addressTextFooter {
        width: 216%;
    }

    .name,
    .nameContact,
    .phone,
    .phoneContact,
    .email,
    .emailContact,
    .message,
    .messageContact,
    .firstName,
    .lastName {
        padding: 1.5vw 0 1.5vw 1.5vw;
        font-size: 1.4vw;
        margin: .5rem 0;
        color: black !important;
    }

    .nameDiv {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
    }

    .checkForm {
        font-size: 2.3vw;
    }

    .btn,
    .btnContact,
    .btnContactDisabled {
        width: 30vw;
        margin-top: 2vw;
        padding: 1.5vw 0 1.5vw 0;
        font-size: 1.2vw;
    }

    .btnContact {
        background-color: white !important;
    }

    .btnContainer {
        text-align: left;
    }
}

@media screen and (min-width:1500px) {

    .contactTitle {
        font-size: 88.1px;
        line-height: 62px;
        margin: 44.5px 0 38px 0;
    }

    .contactWrapperBottom {
        font-size: 27px;
        letter-spacing: -1.08px;
        gap: 0;
        grid-template-columns: 1fr;
    }

    .contactWrapperBottom, :link {
        font-size: 28px;
    }

    .name,
    .nameContact,
    .phoneContact,
    .emailContact,
    .messageContact {
        padding: 8.5px 0 19.5px 11.5px;
        font-size: 19.4px;
    }

    .checkForm {
        font-size: 28.3px;
        line-height: 30px;
    }

    .btn,
    .btnContact,
    .btnContactDisabled {
        width: 290px;
        margin-top: 22px;
        padding: 25.5px 0 21.5px 0;
        font-size: 18.2px;
    }

    .formStyle {
        padding: 0 39px 36px 38px;
    }
}