/* .navbar-toggler-icon {
    filter: invert(1);
} */

.navbar-toggler {
    background-color: #2bbde4 !important;
}

.navbar-toggler-icon {
    filter: invert(1);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent;
    border-color: transparent;
}

.accordion-item {
    background-color: transparent;
    border: none;
}

.accordion {
    --bs-accordion-active-bg: transparent
}

.accordion-button:not(.collapsed) {
    padding: 0;
}

.carousel .control-prev.control-arrow:before {
    background-image: url("./assets/images/left-arrow.png");
    background-size: contain;
    filter: invert(1);
    width: 20vw;
    height: 25vw;
    border-right: 8px solid transparent !important;
}

.carousel .control-arrow:before {
    background-image: url("./assets/images/right-arrow.png");
    background-size: contain;
    filter: invert(1);
    background-repeat: no-repeat;
    width: 20vw;
    height: 20vw;
    border-left: 8px solid transparent !important;
}

.carousel.carousel-slider .control-arrow {
    padding: 0 !important;
}


.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

/* .input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
    margin: 2px;
} */

@media screen and (min-width:728px) {
    .carousel .control-prev.control-arrow:before {
        width: 10vw !important;
        height: 10vw;
    }

    .carousel .control-arrow:before {
        width: 10vw !important;
        height: 10vw;
    }
}

@media screen and (min-width:992px) {
    .navbar {
        --bs-navbar-nav-link-padding-x: 0.42rem !important;
    }
}