.titleContainer>h1 {
    text-align: center;
    font-size: 6vw;
    font-weight: 800;
    margin-top: 15vw;
    padding: 2vw;
    text-transform: capitalize;
    font-family: var(--font-logo);
    color: var(--color-grey);
}

.logo {
    margin: 8px 0 0 12px;
    height: 83px;
}

.bigCloud {
    width: 124%;
    margin: 21px 0 0 0;
}

.smLinkContainer {
    display: grid;
    grid-template-columns: 11vw 11vw;
    margin: 0 0 -27px 63px;
}

.link,
.igLink {
    width: 10vw;
    top: 44px;
    position: absolute;
}

.link {
    gap: 1vw;
}

.link:hover,
.igLink:hover {
    filter: brightness(1.2);
    transition: 0.2s;
}

.introContainer>h1 {
    font-size: 7.5vw;
    font-family: var(--font-green);
    color: var(--color-green);
    text-align: center;
    margin: 4vw 0 0 0;
}

.introContainer>div>p {
    font-size: 8.58vw;
    font-family: var(--font-green);
    color: var(--color-grey);
    text-align: center;
    margin: 0 0 0 0;
    line-height: 8vw;
}

.vivianSykes {
    font-size: 7.5vw;
    font-family: var(--font-signature);
    color: var(--color-grey);
    font-weight: bold;
    margin: 0 0 0 5vw;
    position: relative;
    z-index: 3;

}

.blueInfoWheel {
    width: 100%;
    background-image: url(../../assets/images/mobile-blue-wheel.png);
    background-size: 100% 100%;
    display: block;
    height: 134vw;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    z-index: 2;

}

.mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/blue-gradient.png);
}

.btnContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5vw;
}

.buyNowBtn,
.sampleBtn {
    font-family: var(--font-btn);
    font-weight: bold;
    font-size: 5.5vw;
    margin: 0 0 35vw 0;
    border: 2px solid white;
    width: 38vw;
    /* align-content: end; */
}

.buyNowBtn {
    background-color: yellowgreen;
    color: rgb(50, 68, 14);
    /* padding: 1vw 0 0 0; */
}

.buyNowBtn:hover {
    background-color: rgb(127, 169, 43);
    border: 2px solid white;
    color: white;
    transition: 0.2s;
}

.sampleBtn {
    /* line-height: 6vw; */
    background-color: #2bbde4;
    /* padding: 1vw; */
    color: #154d9b;
}

.sampleBtn:hover {
    background-color: #259dbf;
    border: 2px solid white;
    transition: 0.2s;
}

.headerIntroParagraph,
.headerIntroParagraph2 {
    text-align: left;
    font-size: 4.5vw;
    font-family: var(--font-proxima);
    margin: -2px 0 0 0;
}


.contentContainer {
    width: 100%;
    margin: -9vw 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleContainer {
    width: 100%;
}

.titleContainer>* {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.titleContainer>h3 {
    font-size: 5vw;
    margin: .5rem 0 0 0;
    color: var(--font-secondary);
}

.titleContainer>h2 {
    font-size: 7vw;
    color: var(--font-color-primary);
}

@media screen and (min-width:595px) {
    .sampleBtn>span {
        font-size: 6vw;
    }
}

@media screen and (min-width:728px) {


    .mainContainer {
        background-image: url(../../assets/images/desktop-home.png);
        background-size: cover;
        display: flex;
        flex-direction: column;
    }

    .logo {
        margin: 32px 0 0 17px;
        height: 83px;
    }

    .link,
    .igLink {
        width: 5vw;
        top: -54px;
    }

    .smLinkContainer {
        grid-template-columns: 7vw 11vw;
        margin: 0px 0 -27px 118px;
        position: relative;
        right: 0;
    }

    .bigCloud {
        margin: 33px 0 0 50px;
        width: 30vw;
    }

    .titleContainer {
        width: 48vw;
        margin: 0 0 0 5vw;
    }

    .titleContainer>h1 {
        font-size: 2.4vw;
        margin-top: 2vw;
        text-align: left;
        padding: 0;
        /* width: 45vw; */
    }

    .introContainer {
        width: 45%;
    }

    .introContainer>h1 {
        text-align: left;
        font-size: 2.5vw;
        margin: 2vw 0 0 5vw;
    }

    .introContainer>div>p {
        font-size: 3.5vw;
        line-height: 3.5vw;
        text-align: left;
        margin: 0 0 0 5vw;
    }

    .vivianSykes {
        font-size: 3.5vw;
        margin: 0 0 0 5.5vw;
    }

    .sampleBtn>span {
        font-size: 2.8vw;
    }

    .btnContainer {
        display: flex;
        margin: 3vw 0 0 7vw;
    }

    .buyNowBtn,
    .sampleBtn {
        font-size: 2.5vw !important;
        width: fit-content;
        margin: 0 0 14vw 0;
        /* height: 6vw; */
    }

}

@media screen and (min-width: 900px) {
    .smLinkContainer {
        margin: -20px 0 -27px 142px;
        grid-template-columns: 5vw 11vw;
    }
}

@media screen and (min-width: 1000px) {
    .smLinkContainer {
        margin: -30px 0 -27px 148px;
    }

    .bigCloud {
        width: 280px;
    }

    .link,
    .igLink {
        width: 45px;
    }
}


@media screen and (min-width: 1400px) {
    .smLinkContainer {
        grid-template-columns: 4vw 11vw;
    }
}

@media screen and (min-width: 1500px) {

    .logo {
        margin: 52px 0 0 17px;
        height: 113px;
    }

    .bigCloud {
        width: 360px;
    }

    .link,
    .igLink {
        width: 55px;
    }

    .smLinkContainer {
        grid-template-columns: 64px 11px;
    }

    .titleContainer {
        margin: 0 0 0 75px;
    }

    .titleContainer>h1 {
        font-size: 37.4px;
        margin-top: 42px;
        padding: 0;
        max-width: 601px;
    }

    .introContainer>h1 {
        font-size: 40.4px;
        margin: 26px 0 0 75px;
    }

    .introContainer>div>p {
        font-size: 46.5px;
        line-height: 46.5px;
        margin: 0 0 0 75px;
    }

    .vivianSykes {
        font-size: 53.5px;
        margin: 0 0 0 77.5px;
    }

    .btnContainer {
        margin: 34px 0 0 117px;
        gap: 65px;
    }

    .buyNowBtn,
    .sampleBtn {
        font-size: 41px !important;
        line-height: 41px;
        width: 232px;
        margin: 0 0 214px 0;
        height: 82px;
        padding: 13px;
    }
}