/* TEAM WORK TITLE */
.contentContainer {
    background-image: url("../../assets/images/mobile-green-top.png");
    background-size: cover;
    display: flex;
    justify-content: center;
}

.introContainer {
    width: 90%;
    margin: 10vw 0 0 0;
    color: var(--color-light-grey);
    background: linear-gradient(180deg, rgb(250 252 238 / 85%), rgba(255, 255, 255, 0));
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    position: relative;
    top: -12vw;
    z-index: 1;
}

.titleContainer>h3 {
    text-align: center;
    font-size: 5vw;
    line-height: 17vw;
    font-family: var(--font-green);
    color: #5D9E3E;
    margin: 0 0 -3vw 5vw;
    text-align: left;
}

.titleContainer>h1 {
    font-size: 12vw;
    font-family: var(--font-testimony-signature);
    color: var(--color-grey);
    margin: 0 0 33px 0;
    text-align: center;
}

.titleContainer>img {
    width: 100%;
    height: auto;
    margin: -5vw 0 0 0;
    position: relative;
    top: -5vw;
    z-index: 1;
}

.titleContainer>p {
    font-size: 4.5vw;
    font-family: var(--font-logo);
    font-weight: bold;
    color: var(--color-grey);
    margin: 0 0 3vw 0;
    text-align: center;
}

.imageWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileImg {
    width: 98%;
    height: auto;
}

.titleContainer {
    margin: 1rem 0 0 0;
    width: 100%;
}


@media screen and (min-width:728px) {

    .titleContainer>div>h3 {
        text-align: center;
        line-height: 10vw;
        font-family: var(--font-green);
        color: #5D9E3E;
        margin: 22px 0 -3vw 5vw;
        text-align: left;
        font-size: 2.5vw;
    }

    .titleContainer>div>h1 {
        font-size: 5vw;
        font-family: var(--font-testimony-signature);
        color: var(--color-grey);
        margin: 0 0 7px 0;
        text-align: center;
    }

    .titleContainer>img {
        height: 58vw;
        margin: 46px 0 0 0;
    }

    .titleContainer>div>p {
        font-size: 1.77vw;
        width: 40vw;
        font-family: var(--font-logo);
        font-weight: bold;
        color: var(--color-grey);
        margin: 0 0 2vw 1vw;
        text-align: center;
    }

    .titleContainer {
        display: flex;
        position: relative;
    }

    .contentContainer {
        background-image: url("../../assets/images/desktop-grey-abstract-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 65vw;

    }

    .introContainer {
        background: none;
        position: relative;
    }

    /* 
    .mainContainer>aside {
        background-image: url("../../assets/images/desktop-green-top.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100vw;
        height: 13vw;


    } */

}


@media screen and (min-width:1023px) {

    .titleContainer>img {
        margin: 86px 0 0 0;
    }

    .titleContainer>div>h3 {
        margin: 58px 0 -3vw 5vw;
    }

}

@media screen and (min-width:1500px) {


    .introContainer {
        margin: 150px 0 0 0;
        top: -162px;
    }

    .titleContainer>img {
        height: 818px;
        margin: 156px 0 0 0;
        top: -75px;
    }

    .titleContainer>div>h3 {
        margin: 98px 0 -23px 45px;
        font-size: 36.5px;
        line-height: 140px;
    }

    .titleContainer>div>h1 {
        font-size: 65px;
        line-height: 70px;
    }

    .titleContainer>div>p {
        font-size: 27.77px;
        line-height: 36px;
        width: 618px;
        margin: 20px 0 24px 23px;
    }

    .contentContainer {
        height: 925px;
    }

}